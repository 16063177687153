import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import Ref1 from "../../assets/ref_1.png";
import MetaTags from "react-meta-tags";

class References extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Referenzen</title>
          <meta
            name="description"
            content="Entdecken Sie spannende Referenzen von Pancon – innovative
Lösungen, erfolgreich umgesetzte Projekte, zufriedene Kunden, Distributoren.
Lassen Sie sich von unserer Kompetenz und Erfahrung überzeugen!"
          />
          <meta property="og:title" content="Pancon - Referenzen" />
          <meta
            property="og:description"
            content="Entdecken Sie spannende Referenzen von Pancon – innovative
Lösungen, erfolgreich umgesetzte Projekte, zufriedene Kunden, Distributoren.
Lassen Sie sich von unserer Kompetenz und Erfahrung überzeugen!"
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/referenzen"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/references"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="hero ref">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Referenzen</h1>
            <p className="hero-desc">Unsere Partner</p>
            <button onClick={this.introScroll} className="default inverted">
              Mehr erfahren
            </button>
          </div>
        </div>
        <div
          ref={this.intro}
          className="section flex first intro center-h less-bot"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Referenzen</h2>
              <img src={Ref1}></img>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default References;
