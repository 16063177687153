import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";

class Kontakt extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
    this.form_name = React.createRef();
    this.form_mail = React.createRef();
    this.form_comp = React.createRef();
    this.form_posi = React.createRef();
    this.form_country = React.createRef();
    this.form_msg = React.createRef();
    this.form_gdpr = React.createRef();
    this.form_tel = React.createRef();
    this.state = {
      error: false,
      success: false,
    };
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  sendMail = (e) => {
    if (
      !this.form_gdpr.current.checked ||
      !this.form_mail.current.value ||
      !this.form_comp.current.value ||
      !this.form_name.current.value ||
      !this.form_msg.current.value
    ) {
      this.setState({
        error: true,
      });
    } else {
      this.form_gdpr.current.checked = false;
      this.setState({
        error: false,
        success: true,
      });
      let html =
        "<h1>" +
        "Contact Form Submission Values<br><br>" +
        "</h1><p>Name: " +
        this.form_name.current.value +
        "</p>" +
        "<p>E-Mail: " +
        this.form_mail.current.value +
        "</p>" +
        "<p>Company: " +
        this.form_comp.current.value +
        "</p>" +
        "<p>Phone: " +
        this.form_tel.current.value +
        "</p>" +
        "<p>Position: " +
        this.form_posi.current.value +
        "</p>" +
        "<p>Country: " +
        this.form_country.current.value +
        "</p>" +
        "<p>Message: " +
        this.form_msg.current.value +
        "</p>";
      fetch("https://hooks.zapier.com/hooks/catch/7306825/bpqwmit/", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({ html }),
      });
      this.form_name.current.value = "";
      this.form_mail.current.value = "";
      this.form_comp.current.value = "";
      this.form_tel.current.value = "";
      this.form_posi.current.value = "";
      this.form_country.current.value = "";
      this.form_msg.current.value = "";
    }
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Kontakt</title>
          <meta
            name="description"
            content="Kontaktieren Sie Pancon – Ihr Partner für hochwertige
Verbindungstechnologien. Unser Team unterstützt Einkäufer und Ingenieure bei allen
Fragen zu Produkten, Lösungen und Projekten. Wir freuen uns auf Ihre Anfrage!"
          />
          <meta property="og:title" content="Pancon - Kontakt" />
          <meta
            property="og:description"
            content="Kontaktieren Sie Pancon – Ihr Partner für hochwertige
Verbindungstechnologien. Unser Team unterstützt Einkäufer und Ingenieure bei allen
Fragen zu Produkten, Lösungen und Projekten. Wir freuen uns auf Ihre Anfrage!"
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/kontakt"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/contact"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="hero home">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Kontakt</h1>
            <p className="hero-desc">
              Wir freuen uns auf Ihre Kontaktaufnahme!
            </p>
            <button onClick={this.introScroll} className="default inverted">
              Zum Formular
            </button>
          </div>
        </div>
        <div
          ref={this.intro}
          className="section flex first intro center-h less-bot"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Kontakt</h2>
            </div>
            <div>
              <p className="body">
                Nehmen Sie mit uns Kontakt auf.<br></br>Sie erreichen unseren
                Customer Service unter:{" "}
                <a
                  className="accented"
                  href="mailto:customer-service@pancon.de"
                >
                  customer-service@pancon.de
                </a>
              </p>
            </div>
            <div className="contact-cont flex">
              <div className="two-thirds flex">
                <div className="full">
                  <input
                    placeholder="Name"
                    className="i-half"
                    ref={this.form_name}
                  ></input>
                  <input
                    placeholder="E-Mail"
                    className="i-half"
                    ref={this.form_mail}
                  ></input>
                </div>
                <div className="full">
                  <input
                    placeholder="Firma"
                    className="i-half"
                    ref={this.form_comp}
                  ></input>
                  <input
                    placeholder="Telefon"
                    className="i-half"
                    ref={this.form_tel}
                    type="tel"
                  ></input>
                </div>
                <div className="full">
                  <input
                    placeholder="Position"
                    className="i-half"
                    ref={this.form_posi}
                  ></input>
                  <input
                    placeholder="Land"
                    className="i-half"
                    ref={this.form_country}
                  ></input>
                </div>
                <div className="full">
                  <textarea
                    placeholder="Nachricht"
                    className="i-full"
                    ref={this.form_msg}
                  ></textarea>
                </div>
                <label className="w-checkbox checkbox-cont flex row">
                  <input
                    ref={this.form_gdpr}
                    className="checkbox"
                    type="checkbox"
                  ></input>
                  <p className="checkbox-label">
                    Ich stimme der Verarbeitung meiner Daten basierend auf
                    dieser&nbsp;
                    <a
                      href="https://www.pancon.de/de/datenschutzerklaerung"
                      target={"_blank"}
                      className="accented"
                    >
                      Datenschutzerklärung
                    </a>
                    &nbsp; zu.
                  </p>
                </label>
                {this.state.error ? (
                  <div className="full">
                    <p className="body error-msg">
                      Bitte füllen Sie zumindest die Felder Name, Firma, E-Mail und Nachricht
                      aus und stimmen der Datenschutzerklärung zu.
                    </p>
                  </div>
                ) : null}
                {this.state.success ? (
                  <div className="full">
                    <p className="body success-msg">
                      Deine Nachricht wurde erfolgreich versendet! Vielen Dank!
                    </p>
                  </div>
                ) : null}
                <div className="full">
                  <button onClick={this.sendMail} className="default">
                    Senden
                  </button>
                </div>
              </div>
              <div className="third">
                <p className="body">
                  Pancon GmbH<br></br>
                  <br></br>Gesellschaft für elektromechanische Bauelemente
                  <br></br>Kaiser-Friedrich-Promenade 4<br></br>D-61348 Bad
                  Homburg vor der Höhe<br></br>Deutschland<br></br>
                  <br></br>Tel.: +49 6172 9180157<br></br>E-Mail: info@pancon.de
                  <br></br>
                  <br></br>Sie erreichen unseren Customer Service unter
                  folgender Mail-Adresse:{" "}
                  <a
                    className="accented"
                    href="mailto:customer-service@pancon.de"
                  >
                    customer-service@pancon.de
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Kontakt;
